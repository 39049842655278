import React, { FC } from 'react';

import { Button, Col, Divider, Flex, Form, FormInstance, Input, Row, Select } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { MaskedInput } from 'antd-mask-input';
import TextArea from 'antd/lib/input/TextArea';

import AddMerchant from './AddMerchant';
import Account from './Account';
import DataField from './DataField';
import ParameterArray from './ParametrArray';
import ParameterObject from './ParametrObject';

import {
    RULES_OPTION_PARAMETER_TYPE_ARRAY,
    RULES_OPTION_PARAMETER_TYPE_NUMBER,
    RULES_OPTION_PARAMETER_TYPE_OBJECT,
    RULES_OPTION_PARAMETER_TYPE_SELECT,
    RULES_TYPE_CRITERION,
    RULES_TYPE_WARNING,
} from '../../../constants/Rules';
import { DISABLED_WHITESPACE } from '../../../constants/common';
import { PERIODS_TRANSLATE } from '../../../constants/enums';
import { numberToInputString } from '../../../helpers/numberToInputString';
import {
    ArrayProperty,
    ObjProperties,
    OptionsType,
    RuleModel,
    RuleParamModel,
    RuleParamValueObj,
} from '../../../models/RulesModel';
import { EmployeeModel } from '../../../models/UserModel';
import { getUserName } from '../../../helpers/getUserName';

const { List } = Form;
const merchantMask = [
    {
        mask: Number,
        scale: 2,
        signed: false,
        normalizeZeros: false,
        radix: ',',
        mapToRadix: [',', '.'],
    },
];
const activityItems = [
    {
        value: 0,
        label: 'Ні',
    },
    {
        value: 1,
        label: 'Так',
    },
];
const withoutMerchantParamKeys = ['period', 'periodType', 'maxCardNumberCount'];

interface ParamsProps {
    form: FormInstance;
    selectedRule?: RuleModel;
    optionType: OptionsType;
    users?: EmployeeModel[];
    type: 'warning' | 'online';
}

const Params: FC<ParamsProps> = ({ form, selectedRule, optionType, users, type }) => (
    <div>
        <div className="rules-block__sub_title">Параметри</div>
        <div className="rules-block__items">
            <Form.Item name="id" hidden>
                <Input />
            </Form.Item>
            <DataField
                describe="Прапор активності"
                value={selectedRule?.isActive ? 'Так' : 'Ні'}
                optionType={optionType}>
                <Form.Item name="isActive">
                    <Select options={activityItems} />
                </Form.Item>
            </DataField>
            {type === RULES_TYPE_CRITERION && (
                <DataField
                    describe="Тільки для ризикових мерчантів"
                    value={selectedRule?.isOnlyRisk ? 'Так' : 'Ні'}
                    optionType={optionType}>
                    <Form.Item name="isOnlyRisk">
                        <Select options={activityItems} />
                    </Form.Item>
                </DataField>
            )}
            <DataField describe="Назва" value={selectedRule?.description} optionType={optionType}>
                <Form.Item
                    name="description"
                    rules={[
                        { required: true, message: 'Обов`язково' },
                        { whitespace: true, message: DISABLED_WHITESPACE },
                    ]}>
                    <TextArea rows={4} />
                </Form.Item>
            </DataField>
            {type === RULES_TYPE_WARNING && (
                <DataField
                    describe="Призначені користувачі"
                    value={selectedRule?.assignedUsers?.map((user) => getUserName(user))?.join('; ')}
                    optionType={optionType}>
                    <Form.Item name="assignedUsers" rules={[{ required: true, message: 'Обов`язково' }]}>
                        <Select
                            mode="multiple"
                            options={users?.map((user) => ({ value: user.id, label: getUserName(user) }))}
                        />
                    </Form.Item>
                </DataField>
            )}
            <DataField describe="Коротка назва" value={selectedRule?.shortName} optionType={optionType}>
                <Form.Item
                    name="shortName"
                    rules={[
                        { required: true, message: 'Обов`язково' },
                        { whitespace: true, message: DISABLED_WHITESPACE },
                    ]}>
                    <Input />
                </Form.Item>
            </DataField>
            {(selectedRule?.params as RuleParamModel[])?.map((param, index) => {
                const hasMerchants =
                    type === RULES_TYPE_CRITERION &&
                    !withoutMerchantParamKeys.includes(param.key) &&
                    param.merchantValues;

                return (
                    <Flex vertical key={param.id} gap={hasMerchants ? 24 : 0}>
                        <DataField
                            key={param.id}
                            describe={param.label}
                            value={
                                param.type.code === RULES_OPTION_PARAMETER_TYPE_OBJECT && optionType === 'view'
                                    ? Array.isArray(param.value)
                                        ? (param.value as object[])
                                              .map((item: any) => `${item.account}/${item.ob22.toString()}`)
                                              .join('; ')
                                              .toString()
                                        : Object.keys(param.value)
                                              .map(
                                                  (key) =>
                                                      `${(param.properties as ObjProperties)?.[key]?.label}: ${(
                                                          param.value as RuleParamValueObj
                                                      )?.[key]}`,
                                              )
                                              .join('; ')
                                    : param.type.code === RULES_OPTION_PARAMETER_TYPE_ARRAY && optionType === 'view'
                                      ? (param.value as string[]).join('; ')
                                      : param.type.code === RULES_OPTION_PARAMETER_TYPE_SELECT && optionType === 'view'
                                        ? PERIODS_TRANSLATE[param.value as keyof typeof PERIODS_TRANSLATE] || ''
                                        : param.type.code === RULES_OPTION_PARAMETER_TYPE_NUMBER
                                          ? numberToInputString(param.value as number, param.fraction)
                                          : (param.value as string)
                            }
                            optionType={optionType}
                            border={!hasMerchants}>
                            <>
                                <Form.Item name={['params', index, 'id']} hidden>
                                    <Input />
                                </Form.Item>
                                <Form.Item name={['params', index, 'type']} hidden>
                                    <Input />
                                </Form.Item>
                                {param.type?.code === RULES_OPTION_PARAMETER_TYPE_ARRAY ? (
                                    <ParameterArray
                                        name={['params', index, 'value']}
                                        min={(param.properties as ArrayProperty)?.minLength}
                                        max={(param.properties as ArrayProperty)?.maxLength}
                                        label={param.label}
                                    />
                                ) : param.type?.code === RULES_OPTION_PARAMETER_TYPE_OBJECT ? (
                                    Array.isArray(param.value) ? (
                                        <Account paramIndex={index} />
                                    ) : (
                                        <ParameterObject
                                            value={param.value as object}
                                            param={param}
                                            paramIndex={index}
                                        />
                                    )
                                ) : (
                                    <Form.Item
                                        name={['params', index, 'value']}
                                        rules={[{ whitespace: true, message: DISABLED_WHITESPACE }]}>
                                        {param.type?.code === RULES_OPTION_PARAMETER_TYPE_SELECT ? (
                                            <Select options={param.options} />
                                        ) : param.type?.code === RULES_OPTION_PARAMETER_TYPE_NUMBER ? (
                                            <MaskedInput
                                                mask={[
                                                    {
                                                        mask: Number, // enable number mask
                                                        scale: param.fraction ?? 0, // digits after point, 0 for integers
                                                        signed: false, // disallow negative
                                                        normalizeZeros: false,
                                                        radix: ',', // fractional delimiter
                                                        mapToRadix: [',', '.'], // symbols to process as radix
                                                    },
                                                ]}
                                                className="wWide"
                                            />
                                        ) : (
                                            <Input className="wWide" />
                                        )}
                                    </Form.Item>
                                )}
                            </>
                        </DataField>
                        {hasMerchants && (
                            <List name={['params', index, 'merchantValues']}>
                                {(fields, { add, remove }) => {
                                    return (
                                        <>
                                            <Row>
                                                <Col span={1} />
                                                <Col>Cписок не ризикованих мерчантів</Col>
                                            </Row>
                                            {fields.map(({ key, name }) => {
                                                const { merchant, value } =
                                                    form.getFieldValue(['params', index, 'merchantValues', name]) || {};

                                                return (
                                                    <DataField
                                                        describe={merchant?.name}
                                                        key={key}
                                                        optionType={optionType}
                                                        value={value}
                                                        border={false}>
                                                        <Flex align="flex-start" gap={8}>
                                                            <Form.Item name={[name, 'value']}>
                                                                <MaskedInput mask={merchantMask} className="wWide" />
                                                            </Form.Item>
                                                            <Button
                                                                type="primary"
                                                                danger
                                                                onClick={() => remove(name)}
                                                                icon={<DeleteOutlined />}
                                                            />
                                                        </Flex>
                                                    </DataField>
                                                );
                                            })}
                                            {optionType === 'edit' && <AddMerchant onSubmit={(data) => add(data)} />}
                                            <Divider className="rules-block__options_divider" />
                                        </>
                                    );
                                }}
                            </List>
                        )}
                    </Flex>
                );
            })}
        </div>
    </div>
);

export default Params;
