import { useCallback, useState } from 'react';
import { Button, Calendar, Col, Popover, Row, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { REPORT_LIST, ReportInfo } from '../../constants/Reports';
import { RangePickerProps } from 'antd/lib/date-picker';
import { FileService } from '../../services';
import { DailyReportsTypes } from '../../constants/enums/DailyReportTypes';
import { DATE_FORMAT_FOR_QUERY } from '../../constants';
import { InfoCircleOutlined } from '@ant-design/icons';
import Modal from '../../components/common/Modal';
import ReportForm from '../../components/form/report-form/PaymentParamsForm';

const today = dayjs().startOf('day');
const disabledDate: RangePickerProps['disabledDate'] = (current) => current && current > today;

const reportsWithQuery = [DailyReportsTypes.clientIdSumOutPerMonth, DailyReportsTypes.cardsSumOutPerMonth];

const Reports = () => {
    const [selectedReport, setSelectedReport] = useState<ReportInfo | null>(null);
    const [processing, setProcessing] = useState<{ [key in DailyReportsTypes]: boolean }>({
        [DailyReportsTypes.merchantsStatuses]: false,
        [DailyReportsTypes.merchantsStatsByHours]: false,
        [DailyReportsTypes.merchantsAvgSumByHours]: false,
        [DailyReportsTypes.browserIpTransactions]: false,
        [DailyReportsTypes.browserFingerprintTransactions]: false,
        [DailyReportsTypes.clientIdTransactions]: false,
        [DailyReportsTypes.cardNumberClientsCount]: false,
        [DailyReportsTypes.clientIdStatuses]: false,
        [DailyReportsTypes.cardNumberSum]: false,
        [DailyReportsTypes.compareWithXpay]: false,
        [DailyReportsTypes.clientIdA2cWithoutC2a]: false,
        [DailyReportsTypes.clientIdP2pWithouPurchase]: false,
        [DailyReportsTypes.clientIdSumOutPerMonth]: false,
        [DailyReportsTypes.cardsSumOutPerMonth]: false,
        [DailyReportsTypes.clientIdDepositWithoutWin]: false,
        [DailyReportsTypes.clientIdP2pWithouPurchaseByPlatform]: false,
    });
    const [date, setDate] = useState(today);
    const download = useCallback(
        (type: DailyReportsTypes, fileName: string) => {
            setProcessing((prev) => ({ ...prev, [type]: true }));
            FileService.downloadReport(type, fileName, date.format(DATE_FORMAT_FOR_QUERY), () =>
                setProcessing((prev) => ({ ...prev, [type]: false })),
            );
        },
        [setProcessing, date],
    );

    const handleSubmitForm = useCallback(
        (amount: number, period: [Dayjs, Dayjs]) => {
            if (selectedReport) {
                setProcessing((prev) => ({ ...prev, [selectedReport.type]: true }));
                FileService.downloadReportWithQuery(
                    selectedReport.type,
                    `${selectedReport.fileName}_${period[0].format(DATE_FORMAT_FOR_QUERY)}-${period[1].format(
                        DATE_FORMAT_FOR_QUERY,
                    )}`,
                    `startDate=${period[0].format(DATE_FORMAT_FOR_QUERY)}&endDate=${period[1].format(
                        DATE_FORMAT_FOR_QUERY,
                    )}&amount=${amount}`,
                    () => setProcessing((prev) => ({ ...prev, [selectedReport.type]: false })),
                );
            }
        },
        [selectedReport, setProcessing],
    );
    const resetSelectedReport = () => setSelectedReport(null);

    return (
        <>
            <Modal
                title="Параметри звіту"
                width={400}
                open={!!selectedReport}
                onCancel={resetSelectedReport}
                destroyOnClose>
                <ReportForm onSubmit={handleSubmitForm} onClose={resetSelectedReport} />
            </Modal>
            <Row gutter={[64, 24]}>
                <Col className="reports-calendar">
                    <div className="calendar__wrapper">
                        <Calendar
                            value={date}
                            onChange={(value) => setDate(value)}
                            fullscreen={false}
                            mode="month"
                            disabledDate={disabledDate}
                        />
                    </div>
                    <div className="calendar__description">
                        Усі звіти формуються за звітний день з 08:00 по 08:00 попереднього дня
                    </div>
                </Col>
                <Col flex={1}>
                    {REPORT_LIST.map((item) => (
                        <Row
                            gutter={[16, 16]}
                            align="middle"
                            justify="space-between"
                            key={item.name}
                            className="reports-row">
                            <Col>
                                <Space>
                                    <span>{item.name}</span>
                                    {
                                        <Popover
                                            placement="top"
                                            content={<div className="reports-tooltip">{item.description}</div>}>
                                            <InfoCircleOutlined />
                                        </Popover>
                                    }
                                </Space>
                            </Col>
                            <Col>
                                <Button
                                    disabled={processing[item.type]}
                                    loading={processing[item.type]}
                                    onClick={() => {
                                        reportsWithQuery.includes(item.type)
                                            ? setSelectedReport(item)
                                            : download(item.type, item.fileName);
                                    }}>
                                    Завантажити
                                </Button>
                            </Col>
                        </Row>
                    ))}
                </Col>
            </Row>
        </>
    );
};

export default Reports;
