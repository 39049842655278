export enum DailyReportsTypes {
    merchantsStatuses = 'merchants-status',
    merchantsStatsByHours = 'merchants-stats-by-hours',
    merchantsAvgSumByHours = 'merchants-avg-sum-by-hours',
    browserIpTransactions = 'browser-ip-transactions',
    browserFingerprintTransactions = 'browser-fingerprint-transactions',
    clientIdTransactions = 'client-id-transactions',
    cardNumberClientsCount = 'card-number-clients-count',
    clientIdStatuses = 'client-id-status',
    cardNumberSum = 'card-number-sum',
    compareWithXpay = 'compare-with-xpay',
    clientIdA2cWithoutC2a = 'client-id-a2c-without-c2a',
    clientIdP2pWithouPurchase = 'client-id-p2p-without-purchase',
    clientIdSumOutPerMonth = 'client-id-sum-out-per-month',
    cardsSumOutPerMonth = 'cards-sum-out-per-month',
    clientIdDepositWithoutWin = 'client-id-deposit-without-win',
    clientIdP2pWithouPurchaseByPlatform = 'client-id-p2p-without-purchase-by-platform',
}
