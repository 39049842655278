import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGetTransactionsQuery } from '../../redux/api/transactionsApi';
import { Transaction } from '../../models/Transaction';
import { Button, Checkbox, Col, Row, Table, TablePaginationConfig, TableProps, Tag } from 'antd';
import {
    BOOL_FROM_INT_OPTIONS,
    DATE_FORMAT_WITH_TIME,
    simpleRulesQuery,
    STATUS_LOCALE,
    STATUS_OPTIONS,
    TRANSACTION_SESSION_STORAGE_KEY,
    TRANSACTIONS_PARAMETERS,
    VALIDATED_LOCALE,
    VALIDATED_OPTIONS,
} from '../../constants';
import ResizableColumn from '../../components/common/ResizebleColumn';

import { getColumnSearchProps } from '../../helpers/getColumnSearchProps';
import { QueryOptions } from 'odata-query';
import getTopSkip from '../../helpers/getTopSkip';
import { getTransactionsFilters } from '../../helpers/odataFilters/getTransactionsFilter';
import dayjs from 'dayjs';
import { formatCardNumber } from '../../helpers/formatCardNumber';
import { ColumnsType } from 'antd/lib/table';
import { BoolFromInt } from '../../constants/enums/BoolFromInt';
import Modal from '../../components/common/Modal';
import Details from './Details';
import { formatNumberToFinance } from '../../helpers/convertCurrency';
import { useGetRulesQuery } from '../../redux/api/rulesApi';
import { RuleModel } from '../../models/RulesModel';
import { localeCompareSort } from '../../helpers';
import { FileService } from '../../services';
import { SyncOutlined } from '@ant-design/icons';

const defaultOrder = {
    columnKey: TRANSACTIONS_PARAMETERS.TMP_TRANSACTION_DATE,
    field: TRANSACTIONS_PARAMETERS.TMP_TRANSACTION_DATE,
    order: 'descend',
};

const ruleFilter = { ...simpleRulesQuery, filter: [{ or: { type: { code: 'online' } } }] };
const defaultFilters = {};
const mandatoryFilters = { [TRANSACTIONS_PARAMETERS.MERCHANT_IS_RISK.join('/')]: 0 };

function getRuleFilterOption(rules: RuleModel[] | undefined) {
    return rules
        ?.map((rule) => ({
            text: rule.code,
            value: rule.code,
        }))
        .sort((a, b) => localeCompareSort(a, b, 'text'));
}

const Transactions = () => {
    const inputRef = useRef(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [currentFilter, setCurrentFilter] = useState<null | string>(null);
    const [showRelevant, setShowRelevant] = useState(true);
    const [showMonth, setShowMonth] = useState(true);
    const [selected, setSelected] = useState<Transaction | null>(null);
    const [processingReport, setProcessingReport] = useState(false);
    const sizes = useMemo(() => {
        const tableSizeJson = window.localStorage.getItem(TRANSACTION_SESSION_STORAGE_KEY);
        return tableSizeJson ? JSON.parse(tableSizeJson) : null;
    }, []);
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 20,
        showSizeChanger: true,
        showQuickJumper: true,
    });
    const [sorting, setSorting] = useState<any>(defaultOrder);
    const [filters, setFilters] = useState<any>(defaultFilters);
    const { data: rules } = useGetRulesQuery(ruleFilter);
    const resetFilters = () => {
        setFilters(defaultFilters);
        setSorting(defaultOrder);
        setShowRelevant(true);
        setShowMonth(true);
    };
    const query = useMemo<Partial<QueryOptions<Transaction>>>(
        () => ({
            orderBy: sorting?.order
                ? `${sorting?.columnKey} ${sorting.order === 'ascend' ? 'asc' : 'desc'}`
                : undefined,
            count: true,
            filter: [...getTransactionsFilters(filters), mandatoryFilters],
            rules: filters[TRANSACTIONS_PARAMETERS.RULES_CODES],
            monthly: showMonth,
        }),
        [filters, sorting, showMonth],
    );
    const queryData = useMemo<Partial<QueryOptions<Transaction>>>(
        () => ({ ...query, ...getTopSkip(pagination.pageSize, pagination.current) }),
        [query, pagination],
    );

    const { data, isFetching, refetch } = useGetTransactionsQuery(queryData);
    const handleTableChange: TableProps<Transaction>['onChange'] = (newPagination, filters, sorter: any) => {
        setFilters(filters);
        setSorting({ columnKey: sorter.columnKey, order: sorter.order, field: sorter.field });
        setPagination(newPagination);
    };
    const handleShowCardFilterChange = useCallback((visible: boolean) => {
        if (visible) {
            setCurrentFilter(TRANSACTIONS_PARAMETERS.CARD_NUMBER);
        }
    }, []);
    const handleShowIPFilterChange = useCallback((visible: boolean) => {
        if (visible) {
            setCurrentFilter(TRANSACTIONS_PARAMETERS.IP);
        }
    }, []);
    const handleShowFingerPrintFilterChange = useCallback((visible: boolean) => {
        if (visible) {
            setCurrentFilter(TRANSACTIONS_PARAMETERS.FINGER_PRINT);
        }
    }, []);

    useEffect(() => {
        setPagination((currentPagination) => ({ ...currentPagination, total: data?.count }));
    }, [data?.count]);

    const columns: ColumnsType<Transaction> = [
        {
            title: (
                <ResizableColumn colIndex={0} storageKey={TRANSACTION_SESSION_STORAGE_KEY}>
                    ID
                </ResizableColumn>
            ),
            dataIndex: TRANSACTIONS_PARAMETERS.ID,
            key: TRANSACTIONS_PARAMETERS.ID,
            ...getColumnSearchProps<Transaction>(inputRef, 'string'),
            filteredValue: filters[TRANSACTIONS_PARAMETERS.ID] || null,
            sortOrder: sorting.columnKey === TRANSACTIONS_PARAMETERS.ID ? sorting.order : null,
            render: (value: Transaction['id'], record) => (
                <span className="transaction__link" onClick={() => setSelected(record)}>
                    {value}
                </span>
            ),
            width: sizes?.[0] || 60,
            ellipsis: true,
            sorter: true,
        },
        {
            title: (
                <ResizableColumn colIndex={1} storageKey={TRANSACTION_SESSION_STORAGE_KEY}>
                    STATUS
                </ResizableColumn>
            ),
            dataIndex: TRANSACTIONS_PARAMETERS.STATUS,
            key: TRANSACTIONS_PARAMETERS.STATUS,
            filters: STATUS_OPTIONS,
            render: (value: Transaction['status']) =>
                BOOL_FROM_INT_OPTIONS.includes(value as BoolFromInt) ? (
                    <Tag className="alert-priority" color={value === BoolFromInt.TRUE ? 'green' : 'red'}>
                        {value === BoolFromInt.TRUE
                            ? STATUS_LOCALE[BoolFromInt.TRUE]
                            : STATUS_LOCALE[BoolFromInt.FALSE]}
                    </Tag>
                ) : null,
            width: sizes?.[1] || 150,
            filteredValue: filters[TRANSACTIONS_PARAMETERS.STATUS] || null,
            sortOrder: sorting.columnKey === TRANSACTIONS_PARAMETERS.STATUS ? sorting.order : null,
            ellipsis: true,
            sorter: true,
        },
        {
            title: (
                <ResizableColumn colIndex={2} storageKey={TRANSACTION_SESSION_STORAGE_KEY}>
                    RULES
                </ResizableColumn>
            ),
            filterSearch: true,
            filters: getRuleFilterOption(rules),
            filteredValue: filters[TRANSACTIONS_PARAMETERS.RULES_CODES] || null,
            dataIndex: TRANSACTIONS_PARAMETERS.RULES_CODES,
            key: TRANSACTIONS_PARAMETERS.RULES_CODES,
            width: sizes?.[2] || 150,
            ellipsis: true,
        },
        {
            title: (
                <ResizableColumn colIndex={3} storageKey={TRANSACTION_SESSION_STORAGE_KEY}>
                    MERCHANT_ID
                </ResizableColumn>
            ),
            dataIndex: TRANSACTIONS_PARAMETERS.MERCHANT_ID,
            key: TRANSACTIONS_PARAMETERS.MERCHANT_ID.join('/'),
            ...getColumnSearchProps<Transaction>(inputRef, 'string'),
            sortOrder: sorting.columnKey === TRANSACTIONS_PARAMETERS.MERCHANT_ID.join('/') ? sorting.order : null,
            filteredValue: filters[TRANSACTIONS_PARAMETERS.MERCHANT_ID.join('/')] || null,
            width: sizes?.[3] || 150,
            ellipsis: true,
            sorter: true,
        },
        {
            title: (
                <ResizableColumn colIndex={4} storageKey={TRANSACTION_SESSION_STORAGE_KEY}>
                    MERCHANT_NAME
                </ResizableColumn>
            ),
            dataIndex: TRANSACTIONS_PARAMETERS.MERCHANT_NAME,
            key: TRANSACTIONS_PARAMETERS.MERCHANT_NAME.join('/'),
            ...getColumnSearchProps<Transaction>(inputRef, 'string'),
            sortOrder: sorting.columnKey === TRANSACTIONS_PARAMETERS.MERCHANT_NAME.join('/') ? sorting.order : null,
            filteredValue: filters[TRANSACTIONS_PARAMETERS.MERCHANT_NAME.join('/')] || null,
            width: sizes?.[4] || 180,
            ellipsis: true,
            sorter: true,
        },
        {
            title: (
                <ResizableColumn colIndex={5} storageKey={TRANSACTION_SESSION_STORAGE_KEY}>
                    MERCHANT_WEBSITE
                </ResizableColumn>
            ),
            dataIndex: TRANSACTIONS_PARAMETERS.MERCHANT_WEBSITE,
            key: TRANSACTIONS_PARAMETERS.MERCHANT_WEBSITE.join('/'),
            ...getColumnSearchProps<Transaction>(inputRef, 'string'),
            sortOrder: sorting.columnKey === TRANSACTIONS_PARAMETERS.MERCHANT_WEBSITE.join('/') ? sorting.order : null,
            filteredValue: filters[TRANSACTIONS_PARAMETERS.MERCHANT_WEBSITE.join('/')] || null,
            width: sizes?.[5] || 180,
            ellipsis: true,
            sorter: true,
        },
        {
            title: (
                <ResizableColumn colIndex={6} storageKey={TRANSACTION_SESSION_STORAGE_KEY}>
                    MERCHANT_AGREGATOR
                </ResizableColumn>
            ),
            dataIndex: TRANSACTIONS_PARAMETERS.MERCHANT_AGREGATOR,
            key: TRANSACTIONS_PARAMETERS.MERCHANT_AGREGATOR.join('/'),
            ...getColumnSearchProps<Transaction>(inputRef, 'string'),
            sortOrder:
                sorting.columnKey === TRANSACTIONS_PARAMETERS.MERCHANT_AGREGATOR.join('/') ? sorting.order : null,
            filteredValue: filters[TRANSACTIONS_PARAMETERS.MERCHANT_AGREGATOR.join('/')] || null,
            width: sizes?.[6] || 180,
            ellipsis: true,
            sorter: true,
        },
        {
            title: (
                <ResizableColumn colIndex={7} storageKey={TRANSACTION_SESSION_STORAGE_KEY}>
                    MERCHANT_MCC
                </ResizableColumn>
            ),
            dataIndex: TRANSACTIONS_PARAMETERS.MERCHANT_MCC,
            key: TRANSACTIONS_PARAMETERS.MERCHANT_MCC.join('/'),
            ...getColumnSearchProps<Transaction>(inputRef, 'string'),
            sortOrder: sorting.columnKey === TRANSACTIONS_PARAMETERS.MERCHANT_MCC.join('/') ? sorting.order : null,
            filteredValue: filters[TRANSACTIONS_PARAMETERS.MERCHANT_MCC.join('/')] || null,
            width: sizes?.[7] || 180,
            ellipsis: true,
            sorter: true,
        },
        {
            title: (
                <ResizableColumn colIndex={8} storageKey={TRANSACTION_SESSION_STORAGE_KEY}>
                    CARD_NUMBER
                </ResizableColumn>
            ),
            dataIndex: TRANSACTIONS_PARAMETERS.CARD_NUMBER,
            key: TRANSACTIONS_PARAMETERS.CARD_NUMBER,
            ...getColumnSearchProps<Transaction>(inputRef, 'textArea', {
                transformQuery: (value) => value.replace(/[^0-9,]/g, ''),
                close: () => setCurrentFilter(null),
            }),
            filterDropdownOpen: currentFilter === TRANSACTIONS_PARAMETERS.CARD_NUMBER,
            onFilterDropdownOpenChange: handleShowCardFilterChange,
            sortOrder: sorting.columnKey === TRANSACTIONS_PARAMETERS.CARD_NUMBER ? sorting.order : null,
            filteredValue: filters[TRANSACTIONS_PARAMETERS.CARD_NUMBER] || null,
            render: (value: Transaction['cardNumber']) => formatCardNumber(value),
            width: sizes?.[8] || 170,
            ellipsis: true,
            sorter: true,
        },
        {
            title: (
                <ResizableColumn colIndex={9} storageKey={TRANSACTION_SESSION_STORAGE_KEY}>
                    TRANSACTION_ID
                </ResizableColumn>
            ),
            dataIndex: TRANSACTIONS_PARAMETERS.TRANSACTION_ID,
            key: TRANSACTIONS_PARAMETERS.TRANSACTION_ID,
            ...getColumnSearchProps<Transaction>(inputRef, 'string'),
            sortOrder: sorting.columnKey === TRANSACTIONS_PARAMETERS.TRANSACTION_ID ? sorting.order : null,
            filteredValue: filters[TRANSACTIONS_PARAMETERS.TRANSACTION_ID] || null,
            width: sizes?.[9] || 180,
            ellipsis: true,
            sorter: true,
        },
        {
            title: (
                <ResizableColumn colIndex={10} storageKey={TRANSACTION_SESSION_STORAGE_KEY}>
                    TRANSACTION_DATE
                </ResizableColumn>
            ),
            dataIndex: TRANSACTIONS_PARAMETERS.TMP_TRANSACTION_DATE,
            key: TRANSACTIONS_PARAMETERS.TMP_TRANSACTION_DATE,
            render: (value: Transaction['tmpTransactionDate']) => dayjs(value).format(DATE_FORMAT_WITH_TIME),
            ...getColumnSearchProps<Transaction>(inputRef, 'range'),
            sortOrder: sorting.columnKey === TRANSACTIONS_PARAMETERS.TMP_TRANSACTION_DATE ? sorting.order : null,
            filteredValue: filters[TRANSACTIONS_PARAMETERS.TMP_TRANSACTION_DATE] || null,
            width: sizes?.[10] || 200,
            ellipsis: true,
            sorter: true,
        },
        {
            title: (
                <ResizableColumn colIndex={11} storageKey={TRANSACTION_SESSION_STORAGE_KEY}>
                    AMOUNT
                </ResizableColumn>
            ),
            dataIndex: TRANSACTIONS_PARAMETERS.AMOUNT,
            key: TRANSACTIONS_PARAMETERS.AMOUNT,
            ...getColumnSearchProps<Transaction>(inputRef, 'amount'),
            sortOrder: sorting.columnKey === TRANSACTIONS_PARAMETERS.AMOUNT ? sorting.order : null,
            filteredValue: filters[TRANSACTIONS_PARAMETERS.AMOUNT] || null,
            render: (value: Transaction['amount']) => (value ? formatNumberToFinance(value / 100) : null),
            width: sizes?.[11] || 120,
            ellipsis: true,
            sorter: true,
        },
        {
            title: (
                <ResizableColumn colIndex={12} storageKey={TRANSACTION_SESSION_STORAGE_KEY}>
                    FIRST_NAME
                </ResizableColumn>
            ),
            dataIndex: TRANSACTIONS_PARAMETERS.CUSTOMER_FIRST_NAME,
            key: TRANSACTIONS_PARAMETERS.CUSTOMER_FIRST_NAME,
            ...getColumnSearchProps<Transaction>(inputRef, 'string'),
            sortOrder: sorting.columnKey === TRANSACTIONS_PARAMETERS.CUSTOMER_FIRST_NAME ? sorting.order : null,
            filteredValue: filters[TRANSACTIONS_PARAMETERS.CUSTOMER_FIRST_NAME] || null,
            width: sizes?.[12] || 150,
            ellipsis: true,
            sorter: true,
        },
        {
            title: (
                <ResizableColumn colIndex={13} storageKey={TRANSACTION_SESSION_STORAGE_KEY}>
                    LAST_NAME
                </ResizableColumn>
            ),
            dataIndex: TRANSACTIONS_PARAMETERS.CUSTOMER_LAST_NAME,
            key: TRANSACTIONS_PARAMETERS.CUSTOMER_LAST_NAME,
            ...getColumnSearchProps<Transaction>(inputRef, 'string'),
            sortOrder: sorting.columnKey === TRANSACTIONS_PARAMETERS.CUSTOMER_LAST_NAME ? sorting.order : null,
            filteredValue: filters[TRANSACTIONS_PARAMETERS.CUSTOMER_LAST_NAME] || null,
            width: sizes?.[13] || 150,
            ellipsis: true,
            sorter: true,
        },
        {
            title: (
                <ResizableColumn colIndex={14} storageKey={TRANSACTION_SESSION_STORAGE_KEY}>
                    TAX_ID
                </ResizableColumn>
            ),
            dataIndex: TRANSACTIONS_PARAMETERS.CUSTOMER_TAX_ID,
            key: TRANSACTIONS_PARAMETERS.CUSTOMER_TAX_ID,
            ...getColumnSearchProps<Transaction>(inputRef, 'string'),
            sortOrder: sorting.columnKey === TRANSACTIONS_PARAMETERS.CUSTOMER_TAX_ID ? sorting.order : null,
            filteredValue: filters[TRANSACTIONS_PARAMETERS.CUSTOMER_TAX_ID] || null,
            width: sizes?.[14] || 150,
            ellipsis: true,
            sorter: true,
        },
        {
            title: (
                <ResizableColumn colIndex={15} storageKey={TRANSACTION_SESSION_STORAGE_KEY}>
                    IP
                </ResizableColumn>
            ),
            dataIndex: TRANSACTIONS_PARAMETERS.IP,
            key: TRANSACTIONS_PARAMETERS.IP,
            ...getColumnSearchProps<Transaction>(inputRef, 'textArea', {
                transformQuery: (value) => value.replace(/[^0-9.,]/g, ''),
                close: () => setCurrentFilter(null),
            }),
            filterDropdownOpen: currentFilter === TRANSACTIONS_PARAMETERS.IP,
            onFilterDropdownOpenChange: handleShowIPFilterChange,
            sortOrder: sorting.columnKey === TRANSACTIONS_PARAMETERS.IP ? sorting.order : null,
            filteredValue: filters[TRANSACTIONS_PARAMETERS.IP] || null,
            width: sizes?.[15] || 130,
            ellipsis: true,
            sorter: true,
        },
        {
            title: (
                <ResizableColumn colIndex={16} storageKey={TRANSACTION_SESSION_STORAGE_KEY}>
                    PLATFORM
                </ResizableColumn>
            ),
            dataIndex: TRANSACTIONS_PARAMETERS.PLATFORM,
            key: TRANSACTIONS_PARAMETERS.PLATFORM,
            ...getColumnSearchProps<Transaction>(inputRef, 'string'),
            sortOrder: sorting.columnKey === TRANSACTIONS_PARAMETERS.PLATFORM ? sorting.order : null,
            filteredValue: filters[TRANSACTIONS_PARAMETERS.PLATFORM] || null,
            width: sizes?.[16] || 140,
            ellipsis: true,
            sorter: true,
        },
        {
            title: (
                <ResizableColumn colIndex={17} storageKey={TRANSACTION_SESSION_STORAGE_KEY}>
                    TRANSACTION_TERMINAL_ID
                </ResizableColumn>
            ),
            dataIndex: TRANSACTIONS_PARAMETERS.TRANSACTION_TERMINAL_ID,
            key: TRANSACTIONS_PARAMETERS.TRANSACTION_TERMINAL_ID,
            ...getColumnSearchProps<Transaction>(inputRef, 'string'),
            sortOrder: sorting.columnKey === TRANSACTIONS_PARAMETERS.TRANSACTION_TERMINAL_ID ? sorting.order : null,
            filteredValue: filters[TRANSACTIONS_PARAMETERS.TRANSACTION_TERMINAL_ID] || null,
            width: sizes?.[17] || 260,
            ellipsis: true,
            sorter: true,
        },
        {
            title: (
                <ResizableColumn colIndex={18} storageKey={TRANSACTION_SESSION_STORAGE_KEY}>
                    PAYMENT_TYPE
                </ResizableColumn>
            ),
            dataIndex: TRANSACTIONS_PARAMETERS.PAYMENT_TYPE,
            key: TRANSACTIONS_PARAMETERS.PAYMENT_TYPE,
            ...getColumnSearchProps<Transaction>(inputRef, 'string'),
            sortOrder: sorting.columnKey === TRANSACTIONS_PARAMETERS.PAYMENT_TYPE ? sorting.order : null,
            filteredValue: filters[TRANSACTIONS_PARAMETERS.PAYMENT_TYPE] || null,
            width: sizes?.[18] || 170,
            ellipsis: true,
            sorter: true,
        },
        {
            title: (
                <ResizableColumn colIndex={19} storageKey={TRANSACTION_SESSION_STORAGE_KEY}>
                    FINGER_PRINT
                </ResizableColumn>
            ),
            dataIndex: TRANSACTIONS_PARAMETERS.FINGER_PRINT,
            key: TRANSACTIONS_PARAMETERS.FINGER_PRINT,
            ...getColumnSearchProps<Transaction>(inputRef, 'textArea', {
                transformQuery: (value) => value.replace(/ /g, ''),
                close: () => setCurrentFilter(null),
            }),
            filterDropdownOpen: currentFilter === TRANSACTIONS_PARAMETERS.FINGER_PRINT,
            onFilterDropdownOpenChange: handleShowFingerPrintFilterChange,
            sortOrder: sorting.columnKey === TRANSACTIONS_PARAMETERS.FINGER_PRINT ? sorting.order : null,
            filteredValue: filters[TRANSACTIONS_PARAMETERS.FINGER_PRINT] || null,
            width: sizes?.[19] || 150,
            ellipsis: true,
            sorter: true,
        },
        {
            title: (
                <ResizableColumn colIndex={20} storageKey={TRANSACTION_SESSION_STORAGE_KEY}>
                    ORDER_ID
                </ResizableColumn>
            ),
            dataIndex: TRANSACTIONS_PARAMETERS.ORDER_ID,
            key: TRANSACTIONS_PARAMETERS.ORDER_ID,
            ...getColumnSearchProps<Transaction>(inputRef, 'string'),
            sortOrder: sorting.columnKey === TRANSACTIONS_PARAMETERS.ORDER_ID ? sorting.order : null,
            filteredValue: filters[TRANSACTIONS_PARAMETERS.ORDER_ID] || null,
            width: sizes?.[20] || 150,
            ellipsis: true,
            sorter: true,
        },
        {
            title: (
                <ResizableColumn colIndex={21} storageKey={TRANSACTION_SESSION_STORAGE_KEY}>
                    EXTERNAL_TRANSACTION_ID
                </ResizableColumn>
            ),
            dataIndex: TRANSACTIONS_PARAMETERS.EXTERNAL_TRANSACTION_ID,
            key: TRANSACTIONS_PARAMETERS.EXTERNAL_TRANSACTION_ID,
            ...getColumnSearchProps<Transaction>(inputRef, 'string'),
            sortOrder: sorting.columnKey === TRANSACTIONS_PARAMETERS.EXTERNAL_TRANSACTION_ID ? sorting.order : null,
            filteredValue: filters[TRANSACTIONS_PARAMETERS.EXTERNAL_TRANSACTION_ID] || null,
            width: sizes?.[21] || 260,
            ellipsis: true,
            sorter: true,
        },
        {
            title: (
                <ResizableColumn colIndex={22} storageKey={TRANSACTION_SESSION_STORAGE_KEY}>
                    NOTIFICATION_URL
                </ResizableColumn>
            ),
            dataIndex: TRANSACTIONS_PARAMETERS.NOTIFICATION_URL,
            key: TRANSACTIONS_PARAMETERS.NOTIFICATION_URL,
            ...getColumnSearchProps<Transaction>(inputRef, 'string'),
            sortOrder: sorting.columnKey === TRANSACTIONS_PARAMETERS.NOTIFICATION_URL ? sorting.order : null,
            filteredValue: filters[TRANSACTIONS_PARAMETERS.NOTIFICATION_URL] || null,
            width: sizes?.[22] || 230,
            ellipsis: true,
            sorter: true,
        },
        {
            title: (
                <ResizableColumn colIndex={23} storageKey={TRANSACTION_SESSION_STORAGE_KEY}>
                    DESCRIPTION
                </ResizableColumn>
            ),
            dataIndex: TRANSACTIONS_PARAMETERS.DESCRIPTION,
            key: TRANSACTIONS_PARAMETERS.DESCRIPTION,
            ...getColumnSearchProps<Transaction>(inputRef, 'string'),
            sortOrder: sorting.columnKey === TRANSACTIONS_PARAMETERS.DESCRIPTION ? sorting.order : null,
            filteredValue: filters[TRANSACTIONS_PARAMETERS.DESCRIPTION] || null,
            width: sizes?.[23] || 230,
            ellipsis: true,
            sorter: true,
        },
        {
            title: (
                <ResizableColumn colIndex={24} storageKey={TRANSACTION_SESSION_STORAGE_KEY}>
                    REJECT_CODE
                </ResizableColumn>
            ),
            dataIndex: TRANSACTIONS_PARAMETERS.REJECT_CODE,
            key: TRANSACTIONS_PARAMETERS.REJECT_CODE,
            ...getColumnSearchProps<Transaction>(inputRef, 'string'),
            sortOrder: sorting.columnKey === TRANSACTIONS_PARAMETERS.REJECT_CODE ? sorting.order : null,
            filteredValue: filters[TRANSACTIONS_PARAMETERS.REJECT_CODE] || null,
            width: sizes?.[24] || 150,
            ellipsis: true,
            sorter: true,
        },
        {
            title: (
                <ResizableColumn colIndex={25} storageKey={TRANSACTION_SESSION_STORAGE_KEY}>
                    REJECT_MESSAGE
                </ResizableColumn>
            ),
            dataIndex: TRANSACTIONS_PARAMETERS.REJECT_MESSAGE,
            key: TRANSACTIONS_PARAMETERS.REJECT_MESSAGE,
            ...getColumnSearchProps<Transaction>(inputRef, 'string'),
            sortOrder: sorting.columnKey === TRANSACTIONS_PARAMETERS.REJECT_MESSAGE ? sorting.order : null,
            filteredValue: filters[TRANSACTIONS_PARAMETERS.REJECT_MESSAGE] || null,
            width: sizes?.[25] || 150,
            ellipsis: true,
            sorter: true,
        },
        {
            title: (
                <ResizableColumn colIndex={26} storageKey={TRANSACTION_SESSION_STORAGE_KEY}>
                    VALIDATE
                </ResizableColumn>
            ),
            dataIndex: TRANSACTIONS_PARAMETERS.VALIDATED,
            key: TRANSACTIONS_PARAMETERS.VALIDATED,
            filters: VALIDATED_OPTIONS,
            sortOrder: sorting.columnKey === TRANSACTIONS_PARAMETERS.VALIDATED ? sorting.order : null,
            filteredValue: filters[TRANSACTIONS_PARAMETERS.VALIDATED] || null,
            render: (value: Transaction['validated']) =>
                BOOL_FROM_INT_OPTIONS.includes(value as BoolFromInt)
                    ? value === BoolFromInt.TRUE
                        ? VALIDATED_LOCALE[BoolFromInt.TRUE]
                        : VALIDATED_LOCALE[BoolFromInt.FALSE]
                    : null,
            width: sizes?.[26] || 150,
            ellipsis: true,
            sorter: true,
        },
    ];

    useEffect(() => {
        setFilters((prev: any) => ({ ...prev, [TRANSACTIONS_PARAMETERS.STATUS]: showRelevant ? null : [0] }));
    }, [showRelevant]);

    const tableHeight = (wrapperRef.current as HTMLDivElement)?.offsetHeight;

    return (
        <div className="alert__content" ref={wrapperRef}>
            <Modal
                width={800}
                destroyOnClose
                title="Данні транзакції"
                onCancel={() => setSelected(null)}
                open={!!selected}
                footer={null}>
                {selected && <Details model={selected} />}
            </Modal>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Row gutter={[16, 8]}>
                        <Col flex={1}>
                            <Row align="middle" gutter={16}>
                                <Col>
                                    <Checkbox checked={showRelevant} onChange={() => setShowRelevant((prev) => !prev)}>
                                        Показати всі
                                    </Checkbox>
                                </Col>
                                <Col>
                                    <Checkbox checked={showMonth} onChange={() => setShowMonth((prev) => !prev)}>
                                        За останній місяць
                                    </Checkbox>
                                </Col>
                                <Col>
                                    <Button
                                        loading={processingReport}
                                        disabled={processingReport}
                                        onClick={() => {
                                            setProcessingReport(true);
                                            FileService.downloadTransactionReport(
                                                query,
                                                () => setProcessingReport(false),
                                                `transactions`,
                                                false,
                                                showMonth,
                                            );
                                        }}>
                                        Завантажити звіт
                                    </Button>
                                </Col>
                                <Col>
                                    <Button onClick={resetFilters}>Скинути фільтри</Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Button icon={<SyncOutlined />} onClick={() => refetch()}>
                                Оновити
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Table
                        size="small"
                        scroll={{
                            x: 600,
                            y: tableHeight ? (wrapperRef.current as HTMLDivElement)?.offsetHeight - 154 : undefined,
                        }}
                        rowKey="id"
                        bordered
                        loading={isFetching}
                        columns={columns}
                        dataSource={data?.items}
                        pagination={pagination}
                        onChange={handleTableChange}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default Transactions;
