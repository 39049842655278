import React from 'react';
import { Transaction } from '../../models/Transaction';
import { Col, Row, Table } from 'antd';
import dayjs from 'dayjs';
import { BOOL_FROM_INT_OPTIONS, DATE_FORMAT_WITH_TIME, STATUS_LOCALE, VALIDATED_LOCALE } from '../../constants';
import { BoolFromInt } from '../../constants/enums/BoolFromInt';
import { formatCardNumber } from '../../helpers/formatCardNumber';
import { formatNumberToFinance } from '../../helpers/convertCurrency';

interface DetailProps {
    model: Transaction;
}

const getList = (model: Transaction) => {
    return [
        { label: 'ID', value: model.id },
        {
            label: 'STATUS',
            value: BOOL_FROM_INT_OPTIONS.includes(model.status as BoolFromInt)
                ? model.status === BoolFromInt.TRUE
                    ? STATUS_LOCALE[BoolFromInt.TRUE]
                    : STATUS_LOCALE[BoolFromInt.FALSE]
                : null,
        },
        { label: 'CLIENT_ID', value: model.clientId },
        { label: 'MERCHANT_ID', value: model.merchantId },
        { label: 'MERCHANT_NAME', value: model.merchant?.name },
        { label: 'MERCHANT_WEBSITE', value: model.merchant?.website },
        { label: 'MERCHANT_AGREGATOR', value: model.merchant?.agregator },
        { label: 'MERCHANT_MCC', value: model.merchant?.mcc },
        { label: 'CARD_NUMBER', value: formatCardNumber(model.cardNumber) },
        { label: 'TRANSACTION_ID', value: model.transactionId },
        {
            label: 'TRANSACTION_DATE',
            value: model.transactionDate ? dayjs(model.transactionDate).format(DATE_FORMAT_WITH_TIME) : null,
        },
        { label: 'AMOUNT', value: model.amount ? formatNumberToFinance(model.amount / 100) : null },
        { label: 'CARD_HOLDER', value: model.cardHolder },
        { label: 'IP', value: model.ip },
        { label: 'PLATFORM', value: model.platform },
        { label: 'TRANSACTION_TERMINAL_ID', value: model.transactionTerminalId },
        { label: 'PAYMENT_TYPE', value: model.paymentType },
        { label: 'MERCHANT_SUM', value: model.merchantSum },
        { label: 'BROWSER_DATA', value: model.browserData ? JSON.stringify(model.browserData) : null },
        { label: 'CALLBACK_URL', value: model.callbackURL },
        { label: 'FILED_CALLBACK', value: model.failedCallback },
        { label: 'SUCCESS_CALLBACK', value: model.successCallback },
        { label: 'RECIPIENT_WALLET_ID', value: model.recipientWalletId },
        { label: 'RECIPIENT_WALLET_USER_NAME', value: model.recipientWalletUserName },
        { label: 'SENDER_CARD_CRYPTOGRAM', value: model.senderCardCryptogram },
        { label: 'SENDER_CARD_ECI_INDICATOR', value: model.senderCardEciIndicator },
        { label: 'SENDER_CARD_EXP_MON', value: model.senderCardExpMon },
        { label: 'SENDER_CARD_EXP_YEAR', value: model.senderCardExpYear },
        { label: 'SENDER_CARD_GATEWAY', value: model.senderCardGateway },
        { label: 'SENDER_CARD_PAN', value: model.senderCardPAN },
        { label: 'TRANSFER_SUM', value: model.transferSum },
        { label: 'FINGERPRINT', value: model.fingerprint },
        { label: 'ORDER_ID', value: model.orderId },
        { label: 'EXTERNAL_TRANSACTION_ID', value: model.externalTransactionId },
        { label: 'WITHDRAW_TYPE', value: model.withdrawType },
        { label: 'NOTIFICATION_URL', value: model.notificationURL },
        { label: 'CUSTOMER_FIRST_NAME', value: model.customerFirstName },
        { label: 'CUSTOMER_LAST_NAME', value: model.customerLastName },
        { label: 'CUSTOMER_TAX_ID', value: model.customerTaxId },
        { label: 'DESCRIPTION', value: model.description },
        { label: 'REJECT_CODE', value: model.rejectCode },
        { label: 'REJECT_MESSAGE', value: model.rejectMessage },
        {
            label: 'VALIDATE',
            value: BOOL_FROM_INT_OPTIONS.includes(model.validated as BoolFromInt)
                ? model.validated === BoolFromInt.TRUE
                    ? VALIDATED_LOCALE[BoolFromInt.TRUE]
                    : VALIDATED_LOCALE[BoolFromInt.FALSE]
                : null,
        },
    ];
};

const columns = [
    {
        title: 'Правило',
        dataIndex: ['rule', 'code'],
        key: 'ruleid',
    },
    {
        title: 'Найменування правила',
        dataIndex: ['rule', 'description'],
        key: 'ruleDesc',
    }
];

const Details = ({ model }: DetailProps) => (
    <div className="transactions-details">
        <div className="transaction-details__wrapper transactions-details__main">
            {getList(model).map((row) => (
                <Row key={row.label} className="transactions-details__item">
                    <Col span={10}>
                        <b>{row.label}</b>
                    </Col>
                    <Col span={14}>{row.value}</Col>
                </Row>
            ))}
        </div>
        <div className="transaction-details__wrapper transactions-details__rules">
            <Table rowKey="id" columns={columns} dataSource={model.rules} pagination={false} size="small" />
        </div>
    </div>
);

export default Details;
