import { Key, useEffect, useMemo, useRef, useState } from 'react';

import { Row, Table, TablePaginationConfig, TableProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { QueryOptions } from 'odata-query';

import { LeftOutlined } from '@ant-design/icons';

import HistoryRow from '../../../components/common/HistoryRow';
import { DATE_FORMAT, TIME_WITH_SECONDS_FORMAT } from '../../../constants/Alert';
import { systemDictTranslation } from '../../../constants/Dictionary';
import { defaultHistoryQuery, statusCatalog, statusFilter } from '../../../constants/History';
import { HISTORY_NO_DATA } from '../../../constants/common';
import { getColumnSearchProps } from '../../../helpers/getColumnSearchProps';
import { getHistoryFilter } from '../../../helpers/getHistoryFilters';
import getTopSkip from '../../../helpers/getTopSkip';
import { useEscape } from '../../../hooks';
import { DictionaryHistory, DictionaryModel, RecordModel } from '../../../models/DictionaryModel';
import { useGetDictionaryHistoryQuery } from '../../../redux/api/dictionaryApi';

interface HistoryProps {
    dictionary: DictionaryModel;
    selectedRow: Key;
    onClose: () => void;
}
const historyValue = (value: Partial<RecordModel>, dictionary: DictionaryModel) =>
    value ? (
        Object.keys(value).map((key, index) => {
            if (!dictionary.columns.some((item) => item.colOrmName === key)) return null;

            let rowValue = typeof value[key] !== 'object' ? value[key] : HISTORY_NO_DATA;

            if (dictionary.columns.some((item) => item.colOrmName === key && item.type.code === 'dictionary'))
                rowValue = systemDictTranslation[value[key] as keyof typeof systemDictTranslation] ? systemDictTranslation[value[key] as keyof typeof systemDictTranslation] 
            : value[key].code ? value[key].code : value[key];

            if (dictionary.columns.some((item) => item.colOrmName === key && item.type.code === 'boolean'))
                rowValue = value[key] ? 'Так' : 'Ні';

            return <HistoryRow value={rowValue} key={index} />;
        })
    ) : (
        <div>Дані відсутні</div>
    );

const History = (props: HistoryProps) => {
    useEscape(props.onClose);
    const dateInputRef = useRef(null);
    const authorInputRef = useRef(null);
    const defaultFilter = { entryCode: props.dictionary.name, entryId: props.selectedRow };

    const fieldTranslation = useMemo(
        () =>
            props.dictionary.columns.reduce((acc, item) => {
                acc[item.colOrmName] = item.description;
                return acc;
            }, {} as { [key: string]: string }),
        [props.dictionary],
    );

    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
    });

    const [filter, setFilter] = useState<QueryOptions<DictionaryHistory>['filter']>(defaultFilter);

    const queryData = useMemo<Partial<QueryOptions<DictionaryHistory>>>(
        () => ({
            ...defaultHistoryQuery,
            ...getTopSkip(pagination.pageSize, pagination.current),
            filter: filter,
        }),
        [pagination, filter],
    );

    const { data: dictHistory, isLoading, isFetching } = useGetDictionaryHistoryQuery(queryData);

    const count = dictHistory?.count;

    useEffect(() => {
        setPagination((currentPagination) => ({ ...currentPagination, total: count }));
    }, [count]);

    const handleTableChange: TableProps<DictionaryHistory>['onChange'] = (newPagination, filters, _sorter) => {
        setFilter([defaultFilter, ...getHistoryFilter(filters)]);
        setPagination(newPagination);
    };

    const column: ColumnsType<DictionaryHistory> = [
        {
            title: 'Тип',
            dataIndex: 'action',
            key: 'action',
            render: (value) => statusCatalog[value],
            filters: statusFilter,
            width: 120,
            ellipsis: true,
        },
        {
            title: 'Дата',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (value) => moment(value).format(DATE_FORMAT),
            ...getColumnSearchProps<DictionaryHistory>(dateInputRef, 'date'),
            width: 100,
            ellipsis: true,
        },
        {
            title: 'Час',
            dataIndex: 'createdDate',
            key: 'time',
            render: (value) => moment(value).format(TIME_WITH_SECONDS_FORMAT),
            width: 100,
            ellipsis: true,
        },
        {
            title: 'Автор',
            dataIndex: 'userId',
            key: 'user',
            render: (value) => `${value.lastName} ${value.firstName}`,
            ...getColumnSearchProps<DictionaryHistory>(authorInputRef, 'fio'),
            width: 150,
            ellipsis: true,
        },
        {
            title: 'Параметр',
            dataIndex: 'newValue',
            key: 'param',
            render: (value, record) =>
                record.newValue || record.prevValue
                    ? Object.keys(record.newValue || record.prevValue).map((key, index) => (
                          <HistoryRow value={fieldTranslation[key] || key} key={index} />
                      ))
                    : HISTORY_NO_DATA,
            width: 150,
            ellipsis: true,
        },
        {
            title: 'Значення "До"',
            dataIndex: 'prevValue',
            key: 'prevValue',
            render: (value) => historyValue(value, props.dictionary),
            width: 150,
            ellipsis: true,
        },
        {
            title: 'Значення "Після"',
            dataIndex: 'newValue',
            key: 'newValue',
            render: (value) => historyValue(value, props.dictionary),
            width: 150,
            ellipsis: true,
        },
    ];

    return (
        <>
            <Row>
                <div onClick={props.onClose} className={'user-history__title'}>
                    <LeftOutlined />
                    Історія "{props.dictionary?.description}"
                </div>
            </Row>
            <Table
                scroll={{ y: 'calc(100vh - 475px)' }}
                size="small"
                rowKey="id"
                bordered
                loading={isLoading || isFetching}
                columns={column}
                dataSource={dictHistory ? dictHistory.items : []}
                pagination={pagination}
                onChange={handleTableChange}
            />
        </>
    );
};

export default History;
