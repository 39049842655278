import { FC, Key, useCallback, useState } from 'react';
import { Button, Col, Modal, notification, Row, Space } from 'antd';
import { DictionaryModel } from '../../../models/DictionaryModel';
import { useDeleteByIdsMutation } from '../../../redux/api/dictionaryApi';
import CreateDictionaryModal from './CreateDictionaryModal';
import TabsSelect from '../../../components/common/TabsSelect';
import { BASE_NOTIFICATION_CONFIG } from '../../../constants/common';
import { HistoryOutlined } from '@ant-design/icons';
import UploadFile from './UploadFile';
import { ActivityTypes } from '../../../constants/enums/ActivitiTypes';

interface HeaderDictionaryProps {
    dictionary?: DictionaryModel;
    dictionaries?: DictionaryModel[];
    activeTab?: string;
    onChangeTab: (activeKey: string) => void;
    selectedRows: Key[] | null;
    clearSelectedRows: () => void;
    showControls: boolean;
    relevant: ActivityTypes;
    openHistory: () => void;
}

const dictionaryWithoutHistory: Array<string> = ['dict_registry_adress'];

const dictionaryWithHistoryById: Array<string> = ['dict_black_list_clients', 'dict_black_list_cards'];


const HeaderDictionary: FC<HeaderDictionaryProps> = ({
    dictionary,
    dictionaries,
    activeTab,
    onChangeTab,
    selectedRows,
    clearSelectedRows,
    showControls,
    openHistory,
    relevant,
}) => {
    const [deleteRecord] = useDeleteByIdsMutation();
    const [createRecordModal, setCreateRecordModal] = useState(false);
    const toggleCreateUserModal = useCallback(() => {
        setCreateRecordModal((prev) => !prev);
    }, []);

    const showDeleteModal = () => {
        Modal.confirm({
            title: 'Підтвердження',
            content: 'Ви дійсно бажаєте видалити запис?',
            onOk: handleDeleteRecord,
            okText: 'Видалити',
            cancelText: 'Відміна',
            style: { top: '20%' },
        });
    };

    const handleDeleteRecord = useCallback(() => {
        if (dictionary && selectedRows) {
            deleteRecord({
                dict_name: dictionary.name,
                ids: selectedRows,
            })
                .unwrap()
                .then(() => {
                    notification.success({
                        ...BASE_NOTIFICATION_CONFIG,
                        message: `Запис${selectedRows.length !== 1 ? 'и' : ''} успішно видалено`,
                    });
                    clearSelectedRows();
                })
                .catch((error) => notification.error({ ...BASE_NOTIFICATION_CONFIG, message: error.data.message }));
        }
    }, [clearSelectedRows, deleteRecord, dictionary, selectedRows]);

    return (
        <>
            <CreateDictionaryModal
                onCancel={toggleCreateUserModal}
                onSuccess={toggleCreateUserModal}
                dictionary={dictionary}
                createRecordModal={createRecordModal}
            />
            <Row>
                <Col span={5}>
                    <Row justify="start">
                        <TabsSelect
                            options={dictionaries?.map((item) => ({ label: item.description, value: `${item.id}` }))}
                            activeTab={activeTab}
                            onChangeSelect={onChangeTab}
                        />
                    </Row>
                </Col>
                <Col span={19}>
                    <Row justify="end" gutter={[36, 8]}>
                        <Col>
                            <UploadFile dictionary={dictionary} showControls={showControls} relevant={relevant} />
                        </Col>
                        <Col>
                            <Space>
                                <Button
                                    icon={<HistoryOutlined />}
                                    onClick={openHistory}
                                    disabled={!dictionary || dictionaryWithoutHistory.includes(dictionary?.name) || (dictionaryWithHistoryById.includes(dictionary?.name) && !!selectedRows && selectedRows?.length > 1)}
                                    hidden={dictionary && dictionaryWithoutHistory.includes(dictionary?.name)}>
                                    Історія
                                </Button>
                                <Button
                                    type="primary"
                                    danger
                                    onClick={showDeleteModal}
                                    disabled={selectedRows?.length === 0 || !showControls}
                                    hidden={dictionary && dictionary?.name.includes('black')}>
                                    Видалити запис
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={toggleCreateUserModal}
                                    disabled={!dictionary || !showControls}>
                                    Додати запис
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default HeaderDictionary;
