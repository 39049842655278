import { DailyReportsTypes } from './enums/DailyReportTypes';

export interface ReportInfo {
    type: DailyReportsTypes;
    name: string;
    fileName: string;
    description: string;
}

export const REPORT_LIST: ReportInfo[] = [
    {
        type: DailyReportsTypes.merchantsStatuses,
        name: 'Кількіть авторизацій по мерчант ID з розділенням успішні/не успішні',
        fileName: 'merchants-status',
        description: 'Кількість авторизацій за попередній день з розділення на успішні/не успішні',
    },
    {
        type: DailyReportsTypes.merchantsStatsByHours,
        name: 'Погодинна концентрація кількості операцій по марчант ID',
        fileName: 'merchants-stats-by-hours',
        description: 'Погодинна концентрація кількості операцій за попередній день',
    },
    {
        type: DailyReportsTypes.merchantsAvgSumByHours,
        name: 'Середній чек по мерчанту (погодинно)',
        fileName: 'merchants-avg-sum-by-hours',
        description: 'Погодинний показник середнього чеку по мерчанту з попередній день',
    },
    {
        type: DailyReportsTypes.browserIpTransactions,
        name: 'Групування авторизацій по browser IP',
        fileName: 'browser-ip-transactions',
        description: 'Групування авторизацій по browser IP за попередній день, якщо таких операцій більше 5',
    },
    {
        type: DailyReportsTypes.browserFingerprintTransactions,
        name: 'Групування авторизацій по Browser Fingerprint',
        fileName: 'browser-fingerprint-transactions',
        description: 'Групування авторизацій по Browser Fingerprint за попередні день, якщо таких операцій більше 5',
    },
    {
        type: DailyReportsTypes.clientIdTransactions,
        name: 'Групування по номеру особового рахунку',
        fileName: 'client-id-transactions',
        description:
            'Групування по номеру особового рахунку за попередній день, якщо таких операцій по цьому рахунку більше 5',
    },
    {
        type: DailyReportsTypes.cardNumberClientsCount,
        name: 'Поповнення з одного ЕПЗ на 2 і більше різних особових рахунків',
        fileName: 'card-number-clients-count',
        description: 'Поповнення з одного епз на 2 і більше різних особових рахунки за попередній день',
    },
    {
        type: DailyReportsTypes.clientIdStatuses,
        name: 'Кількість відхилень по особовому рахунку більше 5',
        fileName: 'client-id-status',
        description: 'Кількість відхилень за попередній день по особовому рахунку більше 5',
    },
    {
        type: DailyReportsTypes.cardNumberSum,
        name: 'Групування по ЕПЗ, де загальна сума операцій за день більше 20000 грн',
        fileName: 'card-number-sum',
        description: 'Групування по ЕПЗ, якщо загальна сума таких операцій за попередній день більше 20 000 грн',
    },
    // {
    //     type: DailyReportsTypes.compareWithXpay,
    //     name: 'Порівняння спрацьованих правил з XPay',
    //     fileName: 'compare-with-xpay',
    //     description: 'Транзакції, статус яких відрізняється в системі Anti-fraud та Xpay\n',
    // },
    {
        type: DailyReportsTypes.clientIdA2cWithoutC2a,
        name: 'CLIENT_ID виплати на терміналі А2С при відсутності транзакцій по С2А',
        fileName: 'client-id-a2c-without-c2a',
        description: 'CLIENT_ID виплати на терміналі А2С при відсутності транзакцій по С2А',
    },
    {
        type: DailyReportsTypes.clientIdP2pWithouPurchase,
        name: 'Особисті рахунки, з різними ЕПЗ для поповнення та виплат',
        fileName: 'client-id-p2p-without-purchase',
        description: 'Особисті рахунки, по яких використовуються різні ЕПЗ для поповнення та виплат',
    },
    {
        type: DailyReportsTypes.clientIdSumOutPerMonth,
        name: 'CLIENT_ID, сума виплат перевищує суму на місяць',
        fileName: 'client-id-sum-out-per-month',
        description: 'CLIENT_ID, сума виплат по яких дорівнює чи перевищує суму на місяць',
    },
    {
        type: DailyReportsTypes.cardsSumOutPerMonth,
        name: 'ЕПЗ, сума виплат перевищує суму на місяць',
        fileName: 'cards-sum-out-per-month',
        description: 'ЕПЗ, сума виплат по яких дорівнює чи перевищує суму на місяць',
    },
    {
        type: DailyReportsTypes.clientIdDepositWithoutWin,
        name: 'CLIENT_ID, виплати deposit_payout на терміналі А2С при відсутності win_payout',
        fileName: 'client-id-deposit-without-win',
        description: 'CLIENT_ID, виплати deposit_payout на терміналі А2С при відсутності транзакцій win_payout',
    },
    {
        type: DailyReportsTypes.clientIdP2pWithouPurchaseByPlatform,
        name: 'Особисті рахунки, з різними ЕПЗ для поповнення та виплат у розрізі Platform',
        fileName: 'client-id-p2p-without-purchase-by-platform',
        description: 'Особисті рахунки, по яких використовуються різні ЕПЗ для поповнення та виплат у розрізі середовище проведення операції',
    },
];
