import { createApi } from '@reduxjs/toolkit/query/react';

import customBaseQuery from './common/customBaseQuery';
import buildQuery, { QueryOptions } from 'odata-query';
import { Transaction } from '../../models/Transaction';

type TransactionsResponse = {
    count: number;
    items: Transaction[];
};
export const transactionsApi = createApi({
    reducerPath: 'transactionsApi',
    baseQuery: customBaseQuery,
    tagTypes: ['Transactions'],
    endpoints: (builder) => ({
        getTransactions: builder.query<TransactionsResponse, Partial<QueryOptions<Transaction> & {monthly: number, rules: number[]}>>({
            query(queryData) {
                const queryString = buildQuery(queryData);
                const rulesFilter = queryData.rules ? '&rules=' + queryData.rules?.toString() : '';
                const monthlyFilter = queryData.monthly ? '&monthly=' + +queryData.monthly : '&monthly=0';
                return {
                    url: `process/transactions${queryString}${rulesFilter}${monthlyFilter}`,
                    method: 'GET',
                };
            },
            providesTags: ['Transactions'],
        }),
        getTransactionById: builder.query<Transaction, number>({
            query(id) {
                return {
                    url: `process/transactions/${id}`,
                    method: 'GET',
                };
            },
        }),
    }),
});

export const { useGetTransactionsQuery, useGetTransactionByIdQuery } = transactionsApi;
